.scenarioOptions {
    margin-top: 2vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    /* grid-column-gap: .02vw; */
    /* grid-row-gap: 0px; */
    }
.scenarioOption1 { grid-area: 1 / 1 / 2 / 2; }
.scenarioOption2 { grid-area: 1 / 2 / 2 / 5; }

.scenarioModelingResults {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    padding: 1vw;
    }
    
.scenarioModelingMetrics { grid-area: 1 / 1 / 2 / 2;
    text-align: center; 
    justify-content: center; }

.scenarioModelingMetrics table, td {
    text-align: center;
    vertical-align: middle;
    border: .5vh solid white;
    border-collapse: collapse;
    width: 14vw;
}

/* .scenarioModelingMetrics td {
    border: .5vh solid white;
    border-collapse: collapse;
} */
/* .scenarioModelingMetrics tr {
    outline: .5vh solid white;
} */
.scenarioModelingViz { grid-area: 1 / 2 / 2 / 6;
width:20vw;
vertical-align: middle;
 }

.metricTitle {
    background-color: #7C6674;
}

.scenaioModelingMetricsTable {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    /* grid-column-gap: 10px; */
    grid-row-gap: .5vh;
    align-items: center;
    /* width: 8vw; */
    max-height: 20vh;
    }
    
.keyMetricTitle { 
    grid-area: 1 / 1 / 2 / 2;
 }

.keyMetric { 
    grid-area: 2 / 1 / 3 / 2; 
}

.contextMetricTitle { 
    grid-area: 3 / 1 / 4 / 2;
 }

.contextMetric { 
    grid-area: 4 / 1 / 5 / 2; 
}

.keyFormatting {
    background-color: #7C6674;
    color: white;
    /* padding: 10px; */
    height:10vh;
    position: relative;
}

.contextFormatting {
    background-color: #EFECEE;
    height:10vh;
    position: relative;
    /* padding: 10px; */
}

.centeredMetric {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.verbose {
    font-size: .75vw;
}
.introduction {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* margin-top:25%; */
    /* grid-template-rows: 1fr; */
    /* grid-column-gap: 6vw;

    /* min-height: 70vh; */
    /* margin-bottom: 18vw; */
    /* grid-row-gap: 6vw; */
    /* margin: 6vw; */
    
    align-content: center;
    text-align: center; 
    justify-content: center;
    /* margin-bottom: 11vw; */
    }

.welcome { grid-area: 1 / 2 / 2 / 3;
    text-align: center; 
    font-size: 1.5vw;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* .start {
    font-size: 2vw;
    -webkit-transition-duration:5s;
    -webkit-transition-timing-function:linear;
    -webkit-transition-delay:0s;
    transition-duration:5s;
    transition-timing-function:linear;
    transition-delay:0s;
} */

.start:hover {
    transform: scale(1.5);
    cursor: pointer;
  }

.forward {
    color: #7C6674;
} 
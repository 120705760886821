html,
body {
  margin: 0;
  /* background-color: #7C6674; */
}

#App {
  font-family: 'Avenir', sans-serif;
  height: 100vh;
  /* height: 10% */
  
}

h1 {
  font-size: 2.5vw;
}
hr {
  color: #7C6674;
  height: 2px;
  background-color: #7C6674;
}
.canvasGrid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height:89vh;
}
  
.leftTool { 
  grid-area: 1 / 1 / 2 / 2;
  background: #7C6674;
}
.mainspace {
  grid-area: 1 / 2 / 2 / 9; 
  border-width: 2.5vh;
  border-color: #7C6674;
  /* border-radius: 1px; */
  border-style: solid;
  text-align: center; 
  justify-content: center;
  align-items: center;
justify-items: center;
  /* height: 84vh; */
}


  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px; */
  .workspace {
  display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(7, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
align-items: center;
justify-items: center;
height:84vh;
}

.workTitle { grid-area: 1 / 1 / 2 / 2;
/* padding-top:2vh;  */
}
.workItem { grid-area: 2 / 1 / 7 / 2; 
width: 80%;
}
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  /* padding: 40%; */
  /* background: pink; */
  /* background: purple; */


/* .workNav { grid-area: 7 / 1 / 8 / 2; } */

.workitem > h2, .workitem > h3 {
  /* font-family: 'Klee One' */
}

.workitem > h2{
  text-decoration: underline;
}
.rightTool { 
  grid-area: 1 / 9 / 2 / 10; 
  background: #7C6674;

}

.toDoListIcon {
  position: absolute;
  width: 3vh;
  height: 3vh;
  left: 3vw;
  top:  3vw;
}

.help {
  position: absolute;
  width: 3vh;
  height: 3vh;
  right: 3vw;
  top:  3vw;
}

.popUp {
  position: absolute;
  top: 20px;
  left: 222px;
  right: 222px;
  bottom: 20px;
  background-color: rgba(124, 102, 116);
  color: white;
  text-align:center;
}

.closePopUp {
  font-size: 2.5vh;
}
.checkBox { 
  text-align: right;
}
.listItem { 
  text-align: left;
}

.toDoList {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 3vw;
  grid-row-gap: 2vw;
  font-size: 2.5vh;
  padding: 30px 30px;
  }
    
.checkBox1 { grid-area: 1 / 1 / 2 / 2; }
.listItem1 { grid-area: 1 / 2 / 2 / 7; }
.checkBox2 { grid-area: 2 / 1 / 3 / 2; }
.listItem2 { grid-area: 2 / 2 / 3 / 7; }
.checkBox3 { grid-area: 3 / 1 / 4 / 2; }
.listItem3 { grid-area: 3 / 2 / 4 / 7; }
.checkBox4 { grid-area: 4 / 1 / 5 / 2; }
.listItem4 { grid-area: 4 / 2 / 5 / 7; }
.checkBox5 { grid-area: 5 / 1 / 6 / 2; }
.listItem5 { grid-area: 5 / 2 / 6 / 7; }
.checkBox6 { grid-area: 6 / 1 / 7 / 2; }
.listItem6 { grid-area: 6 / 2 / 7 / 7; }

.bookCase {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 200px;
  grid-row-gap: 10px;
  }

  .book1 { grid-area: 1 / 1 / 2 / 2; }
  .book2 { grid-area: 1 / 2 / 2 / 3; }
  .book3 { grid-area: 1 / 3 / 2 / 4; }
  .book4 { grid-area: 2 / 1 / 3 / 2; }
  .book5 { grid-area: 2 / 2 / 3 / 3; }
  .book6 { grid-area: 2 / 3 / 3 / 4; }
  .book7 { grid-area: 3 / 1 / 4 / 2; }
  .book8 { grid-area: 3 / 2 / 4 / 3; }
  .book9 { grid-area: 3 / 3 / 4 / 4; }

  .book {
    font-size: 8vh;
  }

  .bookButton {
    color: white;
    background: #7C6674;
    border: none;
  }
  button>p {
    font-family: 'Avenir';
  }

  .moveOn, .goBack, .goTo, .updateScenario {
    border: none;
    color: #7C6674;
    background-color: white;
    font-size: 1.25vw;
    margin-top: 2vh;
    /* position: fixed;
    right: 50%;
    top: 50vh; */
  }

  .overviewOutline {
    font-size: 1.4vw;
    text-align: left;
    width: 60%;
  }

  .outlineItem {
    position: relative;
    padding: 0.67em;
    text-decoration: none;
    background: transparent;
  }

  .outlineItem span {
    -webkit-transition: 0.8s;
    -moz-transition: 0.8s;
    -o-transition: 0.8s;
    transition: 0.8s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.outlineItem:before,
.outlineItem:after {
    content: '';
    position: absolute;
    top: 0.67em;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-transition: .8s,opacity .6s;
    -moz-transition: .8s,opacity .6s;
    -o-transition: .8s,opacity .6s;
    transition: .8s,opacity .6s;
}

.outlineItem:before {
  content: attr(data-hover);
  -webkit-transform: translate(150%,0);
  -moz-transform: translate(150%,0);
  -ms-transform: translate(150%,0);
  -o-transform: translate(150%,0);
  transform: translate(150%,0);
}

.outlineItem:hover span {
    opacity: 0;
    /* font-size: 1vw; */
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
}

.outlineItem:hover:before{
    /* font-size: 1.40vw; */
    opacity: 1;
    -webkit-transform: translate(0,0);
    -moz-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
    -webkit-transition-delay: .4s;
    -moz-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}

.viewOverviewButton {
  font-family:Avenir;
  border: none;
  color: #7C6674;
  background: white;
  font-size: 1.25vw;
  padding:  .5vw;
  text-decoration: underline;
  cursor: pointer;
}

.stepNumber {
  background-color: #7C6674;
  color: white;
  padding-left: .75vw;
  padding-right: .75vw;
  margin-right: .75vw;
}
.sampleLink {
  color: #7C6674;
}
  .updateScenario {
    margin-top: 0px;
   }

  .pagination {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .page-item {
    /* display: float; */
    /* float: left; */
    display: inline-block;
  }

  .page-item .page-link {
    display: block;
    color: #7C6674;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 1vw;
  }
  .page-item-selected {
    font-size: 1.25vw;
  }
  .stepTitle {
    font-size: 2vw;
  }

  label {
    font-size: 1.25vw;
  }

  /* .parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    } */
    
    /* .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 2 / 1 / 3 / 2; }
    .div3 { grid-area: 3 / 1 / 4 / 2; }
    .div4 { grid-area: 1 / 2 / 4 / 5; } */

  .displayResults {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(9, 1fr);
    /* grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr); */
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    justify-items: center;
    }
    
  .displayResultsMetric1 { 
    grid-area: 1 / 1 / 4 / 3; 
    width: 100%;
  text-align: center;
  margin-left: 3vw;;
    /* height: 11vh; */
    /* grid-area: 1 / 1 / 2 / 2; */
  }
  .displayResultsMetric2 { 
    grid-area: 4 / 1 / 7 / 3; 
    width: 100%;
    margin-left: 3vw;;
    /* grid-area: 2 / 1 / 3 / 2; */
  }
  .displayResultsMetric3 { 
    grid-area: 7 / 1 / 10 / 3; 
    width: 100%;
    margin-left: 3vw;;
    /* grid-area: 3 / 1 / 4 / 2; */
  }
  .displayResultsViz { 
    grid-area: 1 / 3 / 10 / 8; 
    /* align-items: center;
    justify-items: center; */
    width: 100%;
    /* height: 50%; */
    /* grid-area: 1 / 2 / 4 / 7; */
  }

  .scenarioLine {
    fill:none;
    stroke:#7C6674;
    stroke-width: 5;
    stroke-linejoin: round;
    stroke-linecap:round;
  }

  /* .canvasNavigation{
    background:blue;
    position:fixed;
    top:125%;
    left:50%;
    transform: translate(-50%, -50%);
  } */

  .recordNav {
    /* text-align: center; */
    position: flex;
    /* height: 20vh; */
    /* margin-top: 1vh; */
    justify-content: center;
    align-items: center;
    justify-items: center;
    /* background-color: blue; */
  }

  .contextSelectionForm {
    postion: fixed;
    justify-content: center;
    align-items: center;
    justify-items: center;
    /* height: 90%; */
  }

  .goToMenu {
    /* height: 1vh;
    min-height: 1vh; */
    /* width: 20vw; */
    /* position: flex; */
    /* text-align: center;
    justify-content: center;
    align-items: center; */
    font-size:.5vw;
    /* justify-items: center; */
    /* background-color: blue; */
  }
  .css-b62m3t-container {
    font-size:1.25vw;
  }
  .goToMenu .css-b62m3t-container  {
    /* background-color:blue; */
    width:20vw;
    /* height:10px; */
    font-size:1vw;
    /* height:5vh; */
    /* position: flex; */
    /* text-align: center;
    justify-content: center;
    align-items: center; */
      position: absolute;
  left: 50%;
  top: 88%;
  transform: translate(-50%, -50%);
  /* padding: 10px; */
  }

  .goTo {
    position: absolute;
    left: 48.75%;
    top: 85%;
    /* transform: translate(-50%, -50%); */
  }

  .goTo:hover {
    -webkit-animation: none;
    -webkit-transform: scale(1.25);
    animation: none;
    transform: scale(1.25);
    cursor: pointer;
    /* position: absolute; */
    /* left: 50%; */
    /* top: 85%; */
    /* transform: translate(-50%, -50%); */
  }

  .css-1rp8uui-control {
/* height:5vh; */
justify-content: center;
  }
.setNumContextMetric {
  font-family:Avenir;
  font-size:1.25vw;
  width:10vw;
  height:5vh;
  text-align:center;
  color: #7C6674;
  outline:none;
  border-color: hsl(0, 0%, 80%);
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  /* border-radius: 7px; */
  /* border:none; */
}
.setNumContextMetric:focus {
  /* border: px solid #7C6674; */
  border-width: 2px;
  border-color: #7C6674;
}

.goToMenu:focus {
  border-color: #7C6674;

}
  .setContextMetric {
    padding-bottom: 2.5vh;
  }
  .contextSelectionForm .recordNav {
    position:fixed;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
  }

  .changeKeyMetric {
    background-color:#7C6674;
    border: none;
    border-radius: 6px;
    color: white;
    font-family: Avenir;
    font-size: 1vw;
    padding: .5vw;
  }

  .scenarioModelingGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0px;
    justify-content: center;
    align-items: center; 
    grid-row-gap: 0px;
    /* font-size: 1.25vw; */
    font-size: 1vw;

    width: 100%;
    height: 100%;
    padding-left: 2vw;
    padding-bottom: 2vw;
    }
    
    .scenarioModelingGrid > .css-1simbm5-Input {
        font-size: .5vw;
    }

    .css-1simbm5-Input {
      font-size: .5vw;
  }

    .scenarioModelingKeyMetricTitle { 
      grid-area: 1 / 1 / 2 / 3; 
      border: solid 2px #7C6674;
      padding: 1vh 0;
      background-color: #7C6674;
      color: white;
      /* height:5vh; */
    }
    .scenarioModelingKeyMetric { 
      grid-area: 2 / 1 / 3 / 3; 
      /* border: solid 2px #7C6674; */
      padding: 2vh 0;
      width:100%;
      /* font-size: 1vw; */
    }

    .scenarioModelingContextualMetricTitle { 
      grid-area: 3 / 1 / 4 / 3; 
      border: solid 2px #7C6674;
      padding: 1vh 0;
      background-color: #7C6674;
      color: white;
      width:100%;
    }

    .scenarioModelingContextualMetric1 { 
      grid-area: 4 / 1 / 5 / 2;      

      text-align:left;
      width:13vw;
    }
    .scenarioModelingContextualMetric1Value { 
      grid-area: 4 / 2 / 5 / 3;      
      /* width:14vw; */
      text-align:right;
    }
    .scenarioModelingContextualMetric2 { 
      grid-area: 5 / 1 / 6 / 2;      
      width:13vw;
      text-align:left;

    }
    .scenarioModelingContextualMetric2Value { 
      grid-area: 5 / 2 / 6 / 3;      
      /* width:14vw; */
      text-align:right;
    }
    .scenarioModelingContextualMetric3 { 
      grid-area: 6 / 1 / 7 / 2;      
      width:13vw;
      text-align:left; 
    }
    .scenarioModelingContextualMetric3Value { 
      grid-area: 6 / 2 / 7 / 3;      
      /* width:14vw; */
      text-align:right; 
    }
    .scenarioModelingContexutalMetricMenu { 
      grid-area: 7 / 1 / 8 / 3;      
      width:100%;
    }
    .scenarioModelingVisualization { 
      grid-area: 1 / 3 / 8 / 6; 
    }

    .loadingMessage {
      padding-top: 3vh;
    }

    .changeOutcome {
      cursor: pointer;
    }

    .helpIcon {
      font-size: .85vw;
      padding-bottom: .15vw;
    }

  .tippy-box  {
    background-color: #7C6674;
    font-size: 1vw;
    text-align: center;
  }
  .tippy-arrow{
    color: #7C6674; 
  }

.scenario-viz-title{
  /* margin-top:2vh; */
  /* padding-top:100vh; */
  /* margin: 100px; */
  /* background: blue; */
}

.emailme {
  color: #7C6674;
}
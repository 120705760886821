.uploadgrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    text-align:center;
    align-content: center;
    }
    
    .uploadFile {
        margin-top: 24px;
        font-size: 1.25vw;
        background-color: #7C6674;
        border: none;
        color: white;
        padding: 1.25vw 1.25vw;
    }
    .div1 { grid-area: 1 / 1 / 2 / 2; border: 1px;}
    .uploadbutton { grid-area: 1 / 2 / 2 / 3; border: 1px;}
    .div3 { grid-area: 1 / 3 / 2 / 4; border: 1px;}
    .div4 { grid-area: 2 / 1 / 3 / 2; border: 1px;}
    .uploadmessage { grid-area: 2 / 2 / 3 / 3; border: 1px; padding-top: 24px;}
    .div6 { grid-area: 2 / 3 / 3 / 4; border: 1px;}
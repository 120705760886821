/* .content {
    margin-top: 2vw;
    align-items: center;
} */

.back-arrow {
    /* align-items: center; */
    text-align: center;
    margin-top: 2vw;
    margin-bottom: 2vw;
}
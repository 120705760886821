.footer{
    text-align: center;
    padding: 2vw;
    /* margin-top: 10px; */
    bottom: 0;
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    align-items: center;
    /* margin-bottom: 2%; */
    /* background-color: #7C6674; */
    /* color: white; */
    /* bottom: 0;
    
    width: 100%; */
    /* height: 2.5rem;  */
 }
body {
  font-family: Avenir Light;
  color: #7C6674;
}
/* .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  } */
  
  /* .check {
    grid-column: span 1;
    text-align:center;
    justify-content: center;
    align-items: center;
  } */
  /* .task {
    grid-column: span 6;
    text-align: left;
    margin-left: 1.75vw;
  } */
  /* .action {
    grid-column: span 3;
    align-items: left;
  } */

  /* .margin {
    grid-column: span 1;
  } */
  .checkmark {
    font-size: 4vw;
  } 

  .inactive {
    color: #e0dade;
  }

  .active {
    color:#7C6674;
  }

  .icon {
    font-size: 1.5vw;
  }

  /* .icon:hover {
    -webkit-animation: none;
    -webkit-transform: scale(1.4);
    animation: none;
    transform: scale(1.4);
  } */
  .container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    align-items: center;
    /* border-style: solid;
    border-width: 3vw; */
    }
.leftmargin { grid-area: 1 / 1 / 3 / 2; }
.rightmargin { grid-area: 1 / 7 / 3 / 8; }
.check { grid-area: 1 / 2 / 2 / 3; 
          text-align:right;
          justify-content: right;
          align-items: right;
        margin-right: 6vw;
      margin-top: 1.25vw;
    }
.action { grid-area: 1 / 6 / 2 / 7;
  text-align:left;
  justify-content: left;
  align-items: left;
 }
.leftblank { grid-area: 2 / 2 / 3 / 3; }
.rightblank { grid-area: 2 / 6 / 3 / 7; }
.title { grid-area: 1 / 3 / 2 / 6;
  margin-top: 1vw; 
}
.task { grid-area: 2 / 3 / 3 / 6;
  margin-top: .2vw; 
}

h2 {
  font-size: 1.75vw;
}

p {
  font-size: 1.25vw;
}

.actionItemButton {
  background-color: #7C6674;
  border: none;
  color: white;
  padding: 1.25vw 1.25vw;
  font-size: 1.25vw;
  border-radius: 8px;
}
 
button:hover {
  -webkit-animation: none;
  -webkit-transform: scale(1.5);
  animation: none;
  transform: scale(1.5);
  cursor: pointer;
}

button:active {
  background: #e5e5e5;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
}
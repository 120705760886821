.model {
    display: grid;
    /* grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(3, 1fr); */
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 1fr);


    grid-column-gap: 1.25vw;
    grid-row-gap: 0vw;
    align:center;
    align-items: center;
    justify-content: center;
    /* padding-bottom: 2vw; */
    font-size: 1.25vw;
    padding: 1vw;
    }
    
    .modelTitle {
        font-weight: bold;
        font-size: 1.5vw;
    }
    .directionIcon {
        font-size: 4vw;
    }
    .modelViewToolTips {
        font-size: 1.25vw;
        background-color: white;
        border: none;
        color: #7C6674;
        position: absolute;
        left: 20vw;
        top: 18vh;
    }
    .modelDownloader {
        position: absolute;
        left: 77vw;
        top: 18vh;
    }
    .metricTitleTitle { 
        /* grid-area: 2 / 1 / 2 / 3; */
        grid-area: 1 / 1 / 2 / 3;
        /* grid-area: 1 / 1 / 2 / 2;  */
                    /* width: 6vw; */
                    /* width: 11%; */

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
    .directionTitle {
        /* grid-area: 2 / 3 / 2 / 5; */
        grid-area: 1 / 3 / 2 / 5;
        /* width: 11%; */
        /* grid-area: 1 / 2 / 2 / 3; */
                    /* width: 6vw; */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
    .interpreationTitle { 
        /* grid-area: 2 / 5 / 2 / 9; */
        grid-area: 1 / 5 / 2 / 9;
        /* width: 22%; */
        /* grid-area: 1 / 3 / 2 / 7; */
        /* width: 12vw; */
        display: flex;
        align-items: center;
        justify-content: center;
        overflow:hidden;
    }
    .significanceTitle { 
        /* grid-area: 2 / 9 / 2 / 11; */
        grid-area: 1 / 9 / 2 / 11;
        /* width: 22%; */
        /* grid-area: 1 / 7 / 2 / 11; */
        /* width: 12vw; */
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
    .modelScoreTitle {
        /* grid-area: 2 / 11 / 2 / 13; */
        /* width: 11%; */
        grid-area: 1 / 11 / 2 / 13;
        /* display: flex; */
        align-items: center;
    }
    .metric { 
        /* grid-area: 3 / 1 / 4 / 3; */
        grid-area: 2 / 1 / 4 / 3;
        /* grid-area: 2 / 1 / 4 / 2;  */
                height: 24vh;
                /* width: 6vw; */
                display: flex;
                align-items: center;
                justify-content: center;
            }
    .direction { 
        /* grid-area: 3 / 3 / 4 / 5; */
        grid-area: 2 / 3 / 4 / 5;
        /* grid-area: 2 / 2 / 4 / 3;  */
        height: 24vh;
        /* width: 6vw; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .interpretation { 
        /* grid-area: 3 / 5 / 4 / 9;  */
        grid-area: 2 / 5 / 4 / 9; 
        /* grid-area: 2 / 3 / 4 / 7;  */
        height: 24vh;
        /* width: 12vw; */
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
    .significance { 
        /* grid-area: 3 / 9 / 4 / 11; */
        grid-area: 2 / 9 / 4 / 11;
        /* grid-area: 2 / 7 / 4 / 11;  */
        height: 24vh;
        /* width: 6vw; */
        display: flex;
        align-items: center;
    }

    .modelScore {
        /* grid-area: 3 / 11 / 4 / 13; */
        grid-area: 2 / 11 / 4 / 13;
        
    }
.bar {
    fill: #7C6674;
  }

.labels {
  font-size: .75vw;
  fill: #7C6674;
  /* align: center; */
}

.title {
  font-size: 1vw;
  fill: #7C6674;
}

.barChartTitle {
  font-size:1.25vw;
  fill: #7C6674;
}

/* .tick line {
  stroke: #7C6674;
} */
.tick text, .mark, .axis-label, .category, .barHoverLabel {
  fill: #7C6674;
  text-align: center;
}
.barHoverLabel {
  font-size: .9vw;
}
.tick text {
  font-size: .9vw;
  cursor: default;
}

.category text {
  font-size: .9vw;
  cursor: default;
}

.mark text{
  cursor: default;
}

.tick line {
  stroke: #7C6674;
  opacity: .3;
}

tspan, .barHoverLabel {
  font-weight: bold;
}

.svg-container { 
	display: inline-block;
	position: relative;
	width: 100%;
  /* height: 100%; */
  /* height: 1vh; */
	padding-bottom: 80%; 
  padding-top:0;
	vertical-align: middle; 
	/* overflow: hidden;  */
}

.svg-container-scenario { 
	display: inline-block;
	position: relative;
	width: 100%;
  /* height: 100%; */
  /* height: 1vh; */
	padding-bottom: 100%; 
  padding-top:0;
	vertical-align: middle; 
	/* overflow: hidden;  */
}
.svg-content { 
	display: inline-block;
	position: absolute;
  height:95%;
	top: 10%;
	left: 0;
}

.viz-title {
  /* font-weight: bold; */
  font-size: 1.25vw;
  /* fill: #7C6674; */
}
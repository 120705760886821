html, body {
  max-width: 100%;
  overflow: hidden;
  /* overflow-x: hidden; */
}

#app-container {
    position: relative;
    /* min-height: 100vh; */
  }
  
#app-wrap {
    padding-bottom: 0;    /* Footer height */
  }
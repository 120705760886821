/* .getcalcgrid {
    color: #7C6674;
    font-size: 1.5vw;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    align-content: center;

    } */
  
  .center {
    text-align:center;
  }
    .viewresults { 
      grid-area: 1 / 2 / 2 / 7; 
      /* position:fixed; */
      /* top:275px; */
      /* height:1vh; */
    }
    /* .setmetric { grid-area: 2 / 2 / 3 / 7; } */

    th, td {
      padding: 15px;
      text-align: left;
    }
/* .univariateTitleMetric {
      position:fixed;
      top:275px;
      left:50%;
      text-align:center;
      vertical-align: center;
} */
.calculationButton {
  /* margin-top: 24px; */
  font-size: 1.25vw;
  background-color: #7C6674;
  border: none;
  color: white;
  /* padding: 1.25vw 3vw; */
  width: 9.5vw;
  height: 9.5vw;
}

.submitMetrics {
  margin-top:3vw;
  font-size: 1vw;
  width: 6vw;
  height: 6vw;
}

.downloadButton {
  font-size: 1.25vw;
  background-color: white;
  border: none;
  color: #7C6674;
}



/* .univariateTitle {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  }
   */
  .univariateTitle {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .univariateTitleMetric { grid-area: 1 / 1 / 2 / 12;
    font-size: 1.5vw;  text-align: center; 
    justify-content: center; }
    .univariateTitleDownload { grid-area: 1 / 12 / 2 / 13;  text-align: center; 
      justify-content: center; }

  /* .univariateTitleMetric { grid-area: 1 / 1 / 2 / 3; }
  .univariateTitleDownload { grid-area: 1 / 3 / 2 / 4; }
  .univariateTitleViz { grid-area: 1 / 4 / 2 / 7; } */
.getcolumngrid {
    color: #7C6674;
    font-size: 1.5vw;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    align-content: center;
    }
    
  .center {
    text-align:center;
  }
    .setoutcome { grid-area: 1 / 2 / 2 / 7; }
    .setmetric { grid-area: 2 / 2 / 3 / 7; }
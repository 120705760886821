.parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 6vw;
    /* grid-row-gap: 6vw; */
    margin: 6vw;
    margin-top: 10vw;
    align-content: center;
    margin-bottom: 20vw;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 1 / 3 / 2 / 4; }

    .parent > div {
        background: #7C6674;
        /* color:#EFECEE; */
        /* padding: 1rem; */
        display: grid;
        text-align:center;
        align-content: center;
        position: relative;
        
        /* transition: all .2s ease-in-out;  */
  /* justify-content: center; */
      }
      .parent > div:hover {
        -webkit-animation: none;
        -webkit-transform: scale(1.25);
        animation: none;
        transform: scale(1.25);
        cursor: pointer;
      }

      .parent > div:active {
        background: rgb(185, 185, 185);
        -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
           -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
                box-shadow: inset 0px 0px 5px #c1c1c1;
         outline: none;
      }

      .parent > div::before {
        content: "";
        /* padding-top: 50%; */
        padding-bottom: 100%;
        display: block;
    
      }
    .parent > div::before,
    .parent > div > h1 {
        grid-area: 1 / 1 / 2 / 2;
        color:#EFECEE;
        /* display:table-cell;  */
}
/* h1 {display:table-cell; vertical-align:middle} */
/* .parent > div > h1 {
    vertical-align:middle;
    width: 100%;
    height: 100%;
    object-fit: contain;
  } */
  .parent > div > h1 {
    position: absolute;
    max-width: 100%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
  }
    /* .parent > div::before {
        content: "";
        padding-bottom: 100%;
        display: block;
        background: #7C6674;
      } */
    /* .parent > div {
        background: #7C6674;
        padding: 1rem;
        color: #EFECEE;
        text-align:center;
        content: "";
        padding-bottom: 100%;
        display: block;
      }    */
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* grid-template-columns: 1fr; */
  /* grid-template-rows: 100vh; */
    grid-gap: 1rem;
    justify-content: center;
    align-content: center;

    gap: 4px;
    grid-auto-flow: column;
    /* text-align:center;
    align-items: center;
    justify-items: center; */
    
  }

  .grid > div {
    background: #7C6674;
    padding: 1rem;
  }
  
  .grid > div::before {
    content: "";
    padding-bottom: 100%;
    display: block;
  }
.item {
    background-color: #7C6674;
    color:#EFECEE;
    padding: 1rem;
    content: "";
  padding-bottom: 100%;
  display: block;
}
  
.menu {
    margin-top: 20vh;
    /* position:center; */
  }

  .square {
    padding-top: 5%;
    padding-bottom: 5%;
    display: block;
    /* float:left;
    position: relative; */
    width: 30%;
    height: 30%;
    /* padding-bottom : 30%;  */
    /* = width for a 1:1 aspect ratio */
    /* margin:1.66%; */
    margin:1.5%;
    /* background-color: #7C6674; */
    background-color: #EFECEE;
    color: #7C6674;
    /* background: linear-gradient(#EFECEE, #cabfc6) ; */
    /* overflow:hidden; */
    /* border-radius: 30px; */
    /* cursor: pointer; */
}

.square:hover {
    background-color: #7C6674;
    color: #EFECEE;
  }

  .content {
    /* position:absolute; */
    height:100% - 2*5%;
    width:100% - 2*5% ;
    padding: 5%;
    font-size: 3.5vw;
}

.table{
    display:table;
    height:100%;
    width:100%;
}

.table-cell{
    text-align:center;
    display:table-cell;
    vertical-align:middle;
    /* height:100%;
    width:100%; */
}

.details {
    font-size: 2.5vw;
  }

  select {
    font-size: 1.5vw;
    font-family: "Avenir";
  }